import React from 'react';
import App from 'Src/app/';
import Profile from 'Components/Profile';
import Display from 'Components/Display';

const ProfilePage = () => (
  <App lang="en">
    <Display>
      <Profile />
    </Display>
  </App>
);

export default ProfilePage;
